import React from "react";
import { Footer } from "./Footer";
import { useOpenHours } from "../../../lib/useOpenHours";
import { useFooter } from "../../../lib/useFooter";

interface Props {}

const FooterContainer: React.FC<Props> = () => {
  const hoursData = useOpenHours();
  const libraryHours =
    hoursData?.kontentItemDataHoursOfOperation?.elements?.open_hours.filter(
      (data) => data.hours
    );
  const navigation = useFooter();

  return <Footer libraryHours={libraryHours} navigation={navigation}></Footer>;
};

export default FooterContainer;
