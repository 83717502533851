import { graphql, useStaticQuery } from "gatsby";
import { KontentMultiChoice, StringValue } from "../types";
import { getMultiChoice } from "./multichoice";

export interface HoursText {
  label: string;
  hours: string;
}

export interface OpenHours {
  kontentItemDataHoursOfOperation: {
    elements: {
      open_hours: HoursText[];
      combined_days: {
        open: {
          name: string;
          value: Date;
        };
        close: {
          name: string;
          value: Date;
        };
      }[];
      custom_hours: {
        compiled?: {
          [key: string]: {
            open: {
              name: string;
              value: Date;
            };
            close: {
              name: string;
              value: Date;
            };
          };
        }[];
        value: {
          elements: {
            date: StringValue;
            open: StringValue;
            close: StringValue;
            recurring: KontentMultiChoice<"yes" | "no">;
          };
        }[];
      };
    };
  };
}

export const useOpenHours = () => {
  const hoursData = useStaticQuery<OpenHours>(graphql`
    query HoursQuery {
      kontentItemDataHoursOfOperation(
        system: { name: { eq: "Library Hours" } }
      ) {
        elements {
          open_hours
        }
        system {
          name
        }
      }
    }
  `);

  return hoursData;
};
