import React from "react";
import * as styles from "./Footer.module.css";
import { Link } from "gatsby";
import { HoursText } from "../../../lib/useOpenHours";
import { CookieBanner } from "../../blocks/CookieBanner/CookieBanner";
import { FooterNavigation } from "../../../lib/useFooter";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { KontentLink } from "../../../types";
import { formatLinks } from "../../../lib/links";

interface Props {
  libraryHours: HoursText[];
  navigation?: FooterNavigation;
}

export const Footer: React.FC<Props> = ({ libraryHours, navigation }) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.infoColumn}>
          <div className={styles.infoItem}>
            <h3>Library Hours</h3>
            <div>
              {libraryHours?.map((hours, idx) => (
                <div className={styles.hours} key={idx}>
                  <p>
                    {hours.label}
                    <br />
                    {hours.hours}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.infoItem}>
            <h3>Telephone</h3>
            <a href="tel:8163634600">816.363.4600</a>
          </div>
          <div className={styles.infoItem}>
            <h3>Address</h3>
            <address>
              5109 Cherry Street
              <br />
              Kansas City, Missouri
              <br />
              64110-2498
            </address>
          </div>
        </div>
        {navigation?.links?.modular_content?.length && (
          <div className={styles.linkColumns}>
            <RichTextElement
              value={navigation.links.value}
              linkedItems={navigation.links.modular_content}
              resolveLinkedItem={(item: KontentLink) => {
                const link = formatLinks([item])[0];

                return (
                  <div>
                    <Link to={link.href} target={link.target || "_self"}>
                      {link.label}
                    </Link>
                  </div>
                );
              }}
            />
          </div>
        )}
        <div className={styles.socialColumn}>
          {navigation?.facebookUrl && (
            <a href={navigation?.facebookUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/facebook.svg#img"></use>
              </svg>
              <span className="vis-hidden">Linda Hall Library on Facebook</span>
            </a>
          )}
          {navigation?.twitterUrl && (
            <a href={navigation.twitterUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/twitter.svg#img"></use>
              </svg>
              <span className="vis-hidden">Linda Hall Library on Twitter</span>
            </a>
          )}
          {navigation?.tumblrUrl && (
            <a href={navigation.tumblrUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/tumblr.svg#img"></use>
              </svg>
              <span className="vis-hidden">Linda Hall Library on Tumblr</span>
            </a>
          )}
          {navigation?.vimeoUrl && (
            <a href={navigation.vimeoUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/vimeo.svg#img"></use>
              </svg>
              <span className="vis-hidden">Linda Hall Library on Vimeo</span>
            </a>
          )}
          {navigation?.instagramUrl && (
            <a href={navigation.instagramUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/instagram.svg#img"></use>
              </svg>
              <span className="vis-hidden">
                Linda Hall Library on Instagram
              </span>
            </a>
          )}
          {navigation?.youtubeUrl && (
            <a href={navigation.youtubeUrl} target="_blank">
              <svg>
                <use xlinkHref="/icons/youtube.svg#img"></use>
              </svg>
              <span className="vis-hidden">Linda Hall Library on Youtube</span>
            </a>
          )}
        </div>
      </div>
      <div className={styles.copyright}>
        &copy;{currentYear}, Linda Hall Library, All Rights Reserved
      </div>
      <CookieBanner />
    </section>
  );
};
