import React, { useEffect, useState } from "react";
import * as styles from "./CookieBanner.module.css";
import { Cta } from "../../elements/Cta/Cta";
import Cookies from "js-cookie";
import { Link } from "../../elements/Link/Link";

interface Props {}

const consentCookie = "lhlcookieconsent";

export const CookieBanner: React.FC<Props> = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(consentCookie);

    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleClick = (event: React.MouseEvent) => {
    Cookies.set(consentCookie, "true", { expires: 30 });
    setVisible(false);
  };

  return (
    <>
      {visible ? (
        <section className={styles.cookiebanner}>
          <p>
            This website uses cookies to improve your experience. If you
            continue to use this site, you agree with our{" "}
            <Link to="/privacy-policy" target="_self">
              Privacy Policy
            </Link>
            .
          </p>
          <Cta
            button={true}
            invert={true}
            label="I&nbsp;Agree"
            onClick={handleClick}
          />
        </section>
      ) : null}
    </>
  );
};
