import { graphql, useStaticQuery } from "gatsby";
import { KontentRichText, StringValue } from "../types";

export interface FooterNavigation {
  links: KontentRichText;
  facebookUrl?: string;
  twitterUrl?: string;
  tumblrUrl?: string;
  instagramUrl?: string;
  youtubeUrl?: string;
  vimeoUrl?: string;
}

export interface DataFooter {
  kontentItemDataFooter: {
    system: {
      name: string;
    };
    elements: {
      link_configuration__link: KontentRichText;
      facebook_url: StringValue;
      twitter_url: StringValue;
      tumblr_url: StringValue;
      instagram_url: StringValue;
      youtube_url: StringValue;
      vimeo_url: StringValue;
    };
  };
}

export const useFooter = () => {
  const footerData = useStaticQuery<DataFooter>(graphql`
    query DataFooter {
      kontentItemDataFooter(system: { name: { eq: "LindaHall.org Footer" } }) {
        id
        system {
          name
        }
        elements {
          link_configuration__link {
            value
            modular_content {
              ...kontentRichTextLink
            }
          }
          facebook_url {
            value
          }
          instagram_url {
            value
          }
          tumblr_url {
            value
          }
          twitter_url {
            value
          }
          youtube_url {
            value
          }
          vimeo_url {
            value
          }
        }
      }
    }
  `);

  if (!footerData?.kontentItemDataFooter) return;

  return {
    links: footerData?.kontentItemDataFooter?.elements.link_configuration__link,
    facebookUrl: footerData?.kontentItemDataFooter?.elements.facebook_url.value,
    twitterUrl: footerData?.kontentItemDataFooter?.elements.twitter_url.value,
    tumblrUrl: footerData?.kontentItemDataFooter?.elements.tumblr_url.value,
    instagramUrl:
      footerData?.kontentItemDataFooter?.elements.instagram_url.value,
    youtubeUrl: footerData?.kontentItemDataFooter?.elements.youtube_url.value,
    vimeoUrl: footerData?.kontentItemDataFooter?.elements.vimeo_url.value,
  };
};
